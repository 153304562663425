
import { Options } from "vue-class-component";
import ComponentConfigMixins from "../ComponentConfigMixins";

@Options({
    name: "flowable-common-form-components-table-list-config",
})
export default class TableListConfig extends ComponentConfigMixins {
    get columns() {
        return this._value.columns.filter((c: any) => c.valueType === "Number");
    }
}
